import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { AuthProvider } from './Context/AuthContext';
import { AccessProvider } from './Context/AccessContext';
import RouteApp from './components/App';


import './index.css';

import Home from './components/Home';
import Login from './components/Auth';
// import CadastrosPendentes from './components/CadastrosPendentes';
import DetalhesCadastros from './components/DetalhesCadastroPendente';
// import ItensManutencaoVeiculo from './components/ItensManutencaoVeiculo';
// import ItensManutencaoMotorista from './components/ItensManutencaoMotorista';
import DashboardFinanceiro from './components/DashboardFinanceiro';
import PainelRecursos from './pages/pcp/PainelRecursos';
import OrdemProducaoComparativo from './components/OrdemProducaoFichaRequisitado';
import OrdemProducaoSituacao from './components/OrdemProducaoSituacao';
import DashboardFaturamento from './pages/faturamento/Dashboard';
import CurvaABCClientes from './pages/CurvaABCClientes';
import CurvaABCRepresentantes from './pages/CurvaABCRepresentantes';
import CurvaABCProdutos from './pages/CurvaABCProdutos';
import ApontamentosOperador from './components/ApontamentosOperador';
import Orcamento from './pages/Orcamento';
import AprovacaoOrdensDeCompras from './pages/Estoque/AprovacaoOrdensDeCompras';
import ConsultaPosicaoVeiculo from './pages/frota/ConsultaPosicaoVeiculo';
import AprovarTitulosPagar from './pages/Financeiro/AprovarTitulosPagar';

import LocacaoEquipamentosDashboard from './pages/faturamento/LocacaoEquipamentosDashboard';
import EquipamentosSituacao from './pages/faturamento/EquipamentosSituacao';
import RastreioNotaFiscal from './pages/Logistica/RastreioNotaFiscal';
import DasboardFaturamentoIntercarg from './pages/intercarg/faturamento/Dashboard';
import DashboardFrota from './pages/frota/Dashboard';
import DREIntercarg from './pages/intercarg/financeiro/DRE';
import DREVeiculos from './pages/intercarg/financeiro/DREVeiculos';
import CurvaABCClientesByIntercarg from './pages/intercarg/faturamento/CurvaABCClientes';
import MediaCombustiveis from './pages/frota/MediaCombustiveis';


ReactDOM.render(
  <AuthProvider>
    <AccessProvider>
      <BrowserRouter>
        <Switch>
          <RouteApp path={'/'} exact component={Login} />
          <Route path={'/orcamento_compras/informar_precos/:token'} component={Orcamento} />
          <RouteApp path={'/home'} accessCode='0' component={Home} isPrivate />
          {/* <RouteApp path={'/cadastrosPendentes'} accessCode='' component={CadastrosPendentes} isPrivate /> */}
          <RouteApp path={'/detalhesCadastro/:tipo_cadastro'} accessCode='' component={DetalhesCadastros} isPrivate />
          {/* <RouteApp path={'/itensManutencaoVeiculo'} accessCode='' component={ItensManutencaoVeiculo} isPrivate /> */}
          {/* <RouteApp path={'/itensManutencaoMotorista'} accessCode='' component={ItensManutencaoMotorista} isPrivate /> */}
          <RouteApp path={'/frota/dashboard'} accessCode='1416' component={DashboardFrota} isPrivate />
          <RouteApp path={'/frota/media-combustiveis'} accessCode='1418' component={MediaCombustiveis} isPrivate />
          <RouteApp path={'/dashboard_financeiro'} accessCode='1325' component={DashboardFinanceiro} isPrivate />
          <RouteApp path={'/painel-recursos'} accessCode='1330' component={PainelRecursos} isPrivate />
          <RouteApp path={'/ordem_producao_comparativo'} accessCode='1331' component={OrdemProducaoComparativo} isPrivate />
          <RouteApp path={'/ordem_producao_situacao'} accessCode='1332' component={OrdemProducaoSituacao} isPrivate />
          <RouteApp path={'/dashboard-faturamento'} accessCode='1327' component={DashboardFaturamento} isPrivate />
          <RouteApp path={'/curva-abc-clientes'} accessCode='1326' component={CurvaABCClientes} isPrivate />
          <RouteApp path={'/curva-abc-representantes'} accessCode='1328' component={CurvaABCRepresentantes} isPrivate />
          <RouteApp path={'/curva-abc-produtos'} accessCode='1329' component={CurvaABCProdutos} isPrivate />
          <RouteApp path={'/apontamentos'} accessCode='1338' component={ApontamentosOperador} isPrivate />
          <RouteApp path={'/aprovacao-ordens-compras'} accessCode='1367' component={AprovacaoOrdensDeCompras} isPrivate />
          <RouteApp path={'/consulta-posicao-veiculo'} accessCode='1369' component={ConsultaPosicaoVeiculo} isPrivate />
          <RouteApp path={'/aprovar-titulos-pagar'} accessCode='1370' component={AprovarTitulosPagar} isPrivate />
          <RouteApp path={'/faturamento/locacao_equipamentos/dashboard'} accessCode='1396' component={LocacaoEquipamentosDashboard} isPrivate />
          <RouteApp path={'/faturamento/locacao_equipamentos/equipamentos_situacao'} accessCode='1397' component={EquipamentosSituacao} isPrivate />
          <RouteApp path={'/faturamento/dashboard'} accessCode='1405' component={DasboardFaturamentoIntercarg} isPrivate />
          <RouteApp path={'/financeiro/dre/veiculos'} accessCode='1429' component={DREVeiculos} isPrivate />
          <RouteApp path={'/financeiro/dre'} accessCode='1414' component={DREIntercarg} isPrivate />
          <RouteApp path={'/intercarg/faturamento/curva-abc-clientes'} accessCode='1417' component={CurvaABCClientesByIntercarg} isPrivate />
          <Route path={'/logistica/rastreamento/:hash'} component={RastreioNotaFiscal}  />
        </Switch>
      </BrowserRouter>
    </AccessProvider>
  </AuthProvider>,
  document.getElementById('root')
);
